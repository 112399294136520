import { ListListingPageResponse, trpc } from "utils/trpc";
import { InfiniteData, QueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

export function deleteInterfaceFromListListingPageQueryData(
  queryClient: QueryClient,
  projectIds: string[]
) {
  const queryKey = getQueryKey(trpc.projects.listListingPage);

  queryClient.setQueriesData({ queryKey }, updater(projectIds));
}

function updater(projectIds: string[]): unknown {
  return (oldData: unknown) => {
    if (isInfinitQueryResponse(oldData)) {
      const pages = oldData.pages.map((page) => {
        const projects = page.projects.filter(
          (project) => projectIds.indexOf(project.project.id) === -1
        );
        return { ...page, projects };
      });
      return { ...oldData, pages };
    }

    if (isListListingPageResponse(oldData)) {
      const projects = oldData.projects.filter(
        (project) => projectIds.indexOf(project.project.id) === -1
      );
      return { ...oldData, projects };
    }

    return oldData;
  };
}

// Type predicates
// -----------------------------------------------------------------------------

type ListListingPageInfinitQueryData = InfiniteData<ListListingPageResponse>;

function isInfinitQueryResponse(
  arg: unknown
): arg is ListListingPageInfinitQueryData {
  return (
    typeof arg === "object" &&
    arg !== null &&
    "pages" in arg &&
    Array.isArray(arg.pages)
  );
}

function isListListingPageResponse(
  arg: unknown
): arg is ListListingPageResponse {
  return (
    typeof arg === "object" &&
    arg !== null &&
    "projects" in arg &&
    Array.isArray(arg.projects)
  );
}
