import {
  Alert,
  ModalContent,
  ModalContentBody,
  ModalContentFooter,
  ModalContentHeader,
  Spacer,
  Text,
} from "@zapier/design-system";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { projectsPath } from "lib/route-helpers";
import { useRouter } from "next/router";
import { Suspense, useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Project, trpc } from "utils/trpc";
import { ZapRemovalOption } from "server/schemas/zaps";
import { Spinner } from "./Spinner";
import { ZapDeletePrompt } from "./ZapDeletePrompt";
import { styled } from "lib/theme";
import { useCurrentProject } from "lib/context/current-project-context";
import { useQueryClient } from "@tanstack/react-query";
import { deleteInterfaceFromListListingPageQueryData } from "../lib/query-cache-helpers/deleteProjectFromListListingPageQueryData";
import { zrpc } from "@/lib/zrpc";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

type FormData = {
  relatedZapsAction: ZapRemovalOption;
};

function ModalDeleteProjectBody(props: {
  projectId: Project["id"];
  onClosed: () => void;
  returnToProjects?: boolean;
}) {
  const router = useRouter();

  const queryClient = useQueryClient();

  const project = useCurrentProject();
  const [zaps] = trpc.zaps.search.useSuspenseQuery({
    projectId: props.projectId,
  });

  const deleteProject = zrpc.interfaces.useMutation(
    "delete",
    "/api/interfaces/v0/interfaces",
    {
      onSuccess(data) {
        deleteInterfaceFromListListingPageQueryData(
          queryClient,
          data.interface_ids
        );

        props.onClosed();
      },
    }
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: { relatedZapsAction: "disable-zap" },
  });

  const isDeletingZap = watch("relatedZapsAction") === "delete-zap";

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await deleteProject.mutateAsync({
      body: {
        interface_ids: [props.projectId],
        related_zaps_delete_option: data.relatedZapsAction,
      },
    });

    if (props.returnToProjects) {
      const path = projectsPath({ accountId: project?.accountId });
      void router.replace(path);
    }
  };

  return (
    <>
      <ModalContentHeader>
        <Text type="ParagraphHeader3">Delete Interface</Text>
      </ModalContentHeader>
      <ModalContentBody scrollable>
        <form>
          <Text tag="p" type="Body1" color="GrayWarm9">
            Are you sure you want to delete your <b>{project?.name ?? ""}</b>{" "}
            Interface? This action cannot be undone.
          </Text>
          {zaps && zaps?.length > 0 ? (
            <>
              <Spacer height={15} />
              <Controller
                name="relatedZapsAction"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <ZapDeletePrompt
                      prompt="What should happen to the Zaps connected to this Interface?"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />

                    <Spacer height={20} />
                    {field.value === "delete-zap" && (
                      <Alert status="error">
                        {zaps.length} zap{zaps.length > 1 && "s"} will be
                        deleted
                      </Alert>
                    )}
                  </>
                )}
              />
            </>
          ) : null}
        </form>
      </ModalContentBody>
      <ModalContentFooter>
        <ButtonWrapper>
          <Button
            type="submit"
            color="danger"
            isLoading={isSubmitting}
            onClick={() => handleSubmit(onSubmit)()}
          >
            Delete Interface {isDeletingZap ? "& Zaps" : null}
          </Button>
          <Button type="button" color="tertiary" onClick={props.onClosed}>
            Cancel
          </Button>
        </ButtonWrapper>
      </ModalContentFooter>
    </>
  );
}

export function ModalDeleteProject(props: {
  projectId: Project["id"];
  onClosed: () => void;
  returnToProjects?: boolean;
}) {
  return (
    <Modal onClosed={props.onClosed} aria-label="Delete Interface">
      <ModalContent maxWidth="60ch">
        <Suspense fallback={<Spinner height={200} />}>
          <ModalDeleteProjectBody {...props} />
        </Suspense>
      </ModalContent>
    </Modal>
  );
}

export function useModalDeleteProject(
  projectId: Project["id"],
  returnToProjects?: boolean
) {
  const [isOpen, setIsOpen] = useState(false);

  const confirmDelete = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const renderModal = useCallback(() => {
    if (isOpen) {
      return (
        <ModalDeleteProject
          projectId={projectId}
          onClosed={() => setIsOpen(false)}
          returnToProjects={returnToProjects}
        />
      );
    }

    return null;
  }, [isOpen, projectId, returnToProjects]);

  return [confirmDelete, renderModal] as const;
}
