import { ZodSchema, z } from "zod";
import {
  AccountPlan as PrismaAccountPlan,
  PaidFeature as PrismaPaidFeature,
} from "@prisma/client";
import { AccountEntitlementsResponse } from "../services/zapier/types/types";
import { components } from "@zapier/api-clients/apis/permissions/types";
import { operations } from "@zapier/api-clients/apis/interfaces/types";

export type InterfaceOperations = operations;

export type GetJsonResponseFromOperation<
  OperationId extends keyof InterfaceOperations,
  StatusCode extends keyof InterfaceOperations[OperationId]["responses"],
> = InterfaceOperations[OperationId] extends {
  responses: Record<StatusCode, any>;
}
  ? InterfaceOperations[OperationId]["responses"][StatusCode]["content"]["application/json"]
  : never;

export type Operation = {
  type: string;
  path: string;
  validator: ZodSchema;
};

// When adding a new feature, add it to the PaidFeature enum in schema.prisma
export const paidFeatures = z.nativeEnum(PrismaPaidFeature);
export type PaidFeature = z.infer<typeof paidFeatures>;

export const accountPlan = z.nativeEnum(PrismaAccountPlan);
export type AccountPlan = z.infer<typeof accountPlan>;

export const premiumFeatures: PaidFeature[] = [
  "customColors",
  "branding",
  "embedding",
  "analytics",
  "customTokenLength",

  "passwordProtect",
  "managedUsers",

  "chatBotDataSources",
  "conditionalLogic",
  "fileSizeLimit10mb",
  "embedBlock",
  "layoutBlock",
  "stripePayment",
  "extendedFileUploads",

  /**
   * Stytch-related project authorization
   */
  "stytchAuth",
  "projectMagicLinkAuth",
];

export const advancedFeatures: PaidFeature[] = [
  ...premiumFeatures,
  "customDomain",
  "dynamicFilters",
  "hideZapierButton",
  "fileSizeLimit25mb",

  /**
   * Stytch-related project authorization
   */
  "projectSignInWithGoogleAuth",
  "projectApprovedDomainsAuth",
];

export const teamAccountFeatures: PaidFeature[] = ["collaborators"];

export const allFeatures: PaidFeature[] = Array.from(
  new Set(premiumFeatures.concat(advancedFeatures, teamAccountFeatures))
);

export const LOGIN_PAGE_OPERATIONS = [
  /**
   * For checking what kind of authorization methods are set for the project in Stytch.
   */
  "v0_get_stytch_organization",
] as const satisfies (keyof InterfaceOperations)[];

export type LoginPageOperations = (typeof LOGIN_PAGE_OPERATIONS)[number];

export const PUBLISHED_PAGE_OPERATIONS = [
  "v0_get_field_block_uploads_signature",
  "v0_get_field_block_uploads_status",
  "v0_button_trigger_click",
  "v0_get_consumer_me",
  "v0_create_form_submission",
  "v0_create_stripe_checkout_session",
  /**
   * The login page lives on the published page.
   */
  ...LOGIN_PAGE_OPERATIONS,
] as const satisfies (keyof InterfaceOperations)[];

export type PublishedPageOperations =
  (typeof PUBLISHED_PAGE_OPERATIONS)[number];

export const OPERATIONS_EXCLUDED_FROM_AUTHORIZATION = [
  "chatbotsInterfaceCreate",
  "get_interfaces_list",
  "projectsCreate",
  "getProjectMetadata",
  "getFormSubmissionCount",
] as const satisfies (keyof InterfaceOperations)[];

type OperationsExcludedFromAuthorization =
  (typeof OPERATIONS_EXCLUDED_FROM_AUTHORIZATION)[number];

export type AuthenticatedUser = {
  id: string;
  zapierId: number;
  accountIds: number[];
  currentAccountId: number;
  isStaff: boolean;
  entitlements: AccountEntitlementsResponse;
  email: string;
};

export type AuthContext = {
  user?: AuthenticatedUser;
};

export type AssetType = components["schemas"]["AssetType689Enum"];

/**
 * A null permissionLevel means that a Permission API check is not required for the operation. This could be due to one of the following reasons:
 * 1. The operation is only authorized by authenticating the user.
 * 2. The operation is authorized by being a member of the account. Account level checks are not handled by Permissions API yet.
 * 3. The operation is for a pass through endpoint. We simply proxy the request to tables, monolith or other services.
 */
export const PERMISSION_CHECK_NOT_REQUIRED = null;

export type TrpcPermissionConfig = {
  /**
   * The permission level required to access the operation.
   *
   * This is used to determine if the user has the necessary permissions to access the operation.
   */
  permissionLevel: PermissionLevel | typeof PERMISSION_CHECK_NOT_REQUIRED;

  /**
   * Whether we use the new Permissions API and feature access check logic to check for access or the old logic.
   *
   * This flag will be removed when we finish roll out of the new Permissions API and feature access check logic
   */
  enabled: boolean;
};

export type PermissionConfig = {
  zapierId: number;
  currentAccountId: number;
  assetType: AssetType;
  permissionLevel: PermissionLevel;
  assetId: string;
};

export type OperationsIdsThatNeedAuthorization = Exclude<
  keyof InterfaceOperations,
  OperationsExcludedFromAuthorization
>;

export type InterfaceOperationAuthorizationMap = {
  [Id in OperationsIdsThatNeedAuthorization]: {
    operationId: Id;
    parameters: InterfaceOperations[Id] extends { parameters: infer Parameters }
      ? Parameters
      : undefined;
    context: AuthContext;
  } & (InterfaceOperations[Id] extends {
    requestBody?: {
      content: {
        "application/json": infer RequestBody;
      };
    };
  }
    ? { body: RequestBody }
    : { body?: never });
};

export type InterfaceOperationForAuthorization = {
  [Id in OperationsIdsThatNeedAuthorization]: InterfaceOperationAuthorizationMap[Id];
}[OperationsIdsThatNeedAuthorization];

export type BuilderOperationAuthorizationRequest =
  InterfaceOperationAuthorizationMap[OperationsIdsThatNeedAuthorization];

export type BuilderOperationPermissionsMap = {
  [Id in OperationsIdsThatNeedAuthorization]: OperationPermissionConfig<Id>;
};

/**
 * All specified configs in OperationPermissionConfig must pass for the operation to be authorized.
 *
 * If you want to authorize the operation based on a single config, specify the config alone.
 * Specifying multiple configs, means all specified configs must pass.
 *
 */
export type OperationPermissionConfig<
  T extends
    OperationsIdsThatNeedAuthorization = OperationsIdsThatNeedAuthorization,
> = {
  /**
   * The permission level required to access the operation.
   *
   * This is used to determine if the user has the necessary permissions to access the operation.
   */
  permissionLevel: PermissionLevel | null;
  /**
   * The feature access required to access the operation.
   */
  featureAccess?: {
    /**
     * Access is granted if the interface has at least one of the features.
     */
    anyOf?: PaidFeature[];
    /**
     * Access is granted if the interface has ALL of these features.
     */
    allOf?: PaidFeature[];
    /**
     * Access is only granted if the account is on the paid plan or the user is the creator of the project. This is similar to 'collaborators' feature access.
     *
     * We can get rid of this check, if we confirm that when a user downgrades a plan, we remove the other editors they might have added to the project.
     * That way we trust Permissions API 100% and we also save extra calls to the DB. But having it here helps us maintain parity with legacy authz.
     */
    requiresPaidPlanOrIsProjectCreator?: boolean;
  };
  /**
   * Access is granted if the user is a member of the account.
   */
  requireAccountMembership?: boolean;
  /**
   * Custom logic to check for access
   */
  customPermissionCheck?: (
    request: InterfaceOperationAuthorizationMap[T]
  ) => Promise<boolean>;
};

export type PublishedOperationAuthorizationRequest = {
  [Id in PublishedPageOperations | LoginPageOperations]: Omit<
    InterfaceOperationAuthorizationMap[Id],
    "context"
  >;
}[PublishedPageOperations | LoginPageOperations];

export type LoginPageOperationAuthorizationRequest = {
  [Id in LoginPageOperations]: Omit<
    InterfaceOperationAuthorizationMap[Id],
    "context"
  >;
}[LoginPageOperations];

// You can find the list of available Permission levels under 'type Interface' here:
// https://gitlab.com/zapier/team-identity-platform/fga-authorization-models/-/blob/main/models/model.fga?ref_type=heads
export type PermissionLevel =
  | "can_edit"
  | "can_view"
  | "can_view_metadata"
  | "can_view_via_shares"
  | "can_delete"
  | "can_change_owner"
  | "can_share_editor"
  | "can_view_shares"
  | "can_manage_editors"
  | "can_view_interface"
  | "can_delete_interface"
  | "can_edit_interface"
  | "can_add_page"
  | "can_delete_page"
  | "can_edit_page"
  | "can_view_page"
  | "can_view_consumer"
  | "can_add_consumer"
  | "can_delete_consumer"
  | "can_edit_consumer"
  | "can_edit_theme"
  | "can_view_theme"
  | "can_add_custom_domain"
  | "can_delete_custom_domain"
  | "can_view_custom_domain"
  | "can_add_block"
  | "can_edit_block"
  | "can_view_block"
  | "can_view_button"
  | "can_add_subscription"
  | "can_delete_subscription"
  | "can_add_zap"
  | "can_view_zaps"
  | "can_manage_zaps"
  | "can_delete_zap"
  | "can_add_datasource"
  | "can_manage_datasource"
  | "can_view_payments"
  | "can_view_cli_auth"
  | "can_check_cli_auth"
  | "can_edit_auth"
  | "can_check_auth"
  | "can_edit_captcha"
  | "can_edit_idp_settings"
  | "can_view_idp_settings"
  | "can_delete_form"
  | "can_view_form"
  | "can_view_form_output_fields"
  | "can_view_form_submissions"
  | "can_access_all_interfaces";
